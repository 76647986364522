<template>
    <component :is="compName" v-bind="compProps" />
</template>

<script>
//import Navbar from '@/components/MobileNavigation/Navbar.vue';

export default {
    name: 'ComponentsDesktop',
    components:{
        PDPMobileBlackRabbit: () => import('@/components/ProductPage/Mobile-BlackRabbit/Index.vue'),
        ComboPage: () => import('@/components/ComboCart/pages/ComboPage.vue'),
        AccountDashboard: () => import('@/components/Account/Mobile/Dashboard.vue'),
        Navbar: () => import('@/components/MobileNavigation/Navbar.vue'),
        Header: () => import('@/components/MobileNavigation/Header.vue'),
        Favorites: () => import('@/components/Account/FavoritesMobile.vue'),
        Catalog: () => import('@/components/Catalog/Desktop/Index.vue'),
        Search: () => import('@/components/Catalog/Search/Search.vue'),
        BlocksBlackRabbit: () => import('@/components/Blocks/Mobile.vue'),
        GallerySlideBlackRabbit: () => import('@/components/ProductPage/Mobile-BlackRabbit/GallerySlide.vue'),
        NewIn: () => import('@/components/NewIn/NewIn.vue'),
        SocialProfile: () => import('@/components/Social/Profile.vue'),
        SocialMention: () => import('@/components/Social/Mention.vue'),
        BundleList: () => import('@/components/Catalog/BundleList/Mobile.vue'),
        CategoryPage: () => import("@/components/CategoryPage/Index.vue"),
    },
    props:{
        // component name
        compName:{
            type: String
        },
        // component props
        compProps:{
            type: Object
        },
    },
    data(){
        return{
        }
    },
    // mounted() {
    //     console.log('compName', this.compName);
    // },
    // updated(){
    // },
    // created() {
    // },
    // destroyed() {
    // },
    // watch: {
    // },
    // computed: {
    // },
    // methods: {
    // }
}
</script>
